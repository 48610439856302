#preloader {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

#preloader .lds-default {
    margin: 0;
}

#preloader .title {
    color: var(--umw-col-font-light);
    font-size: 5rem;
}

#preloader .loading-info {
    color: var(--umw-col-font-light);
    font-size: 2rem;
}

#preloader button {
    animation: pulse 2s infinite;
    height: 20rem;
    width: 20rem;
}

#preloader button svg path {
    fill: var(--umw-col-font-light);
}
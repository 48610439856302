#choose-group-popup {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    margin: auto;
    height: calc(64% - 4.3rem);
    background-color: var(--umw-col-panel);
    padding: 1rem;
}

#choose-group-popup .item-title {
    font-size: 2.3rem;
    text-align: center;
    display: block;
}

#choose-group-popup .item-icon {
    height: 7rem !important;
    display: block;
}

#choose-group-popup .item-description {
    display: block;
    text-align: center;
    font-size: 1.3rem;
    margin-top: 0;
}

#group-btn-holder {
    border-left: 1px solid rgba(0,0,0,0.5);
    padding-left: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

.group-btn {
    width: auto;
    height: 45px;
    border-width: 0.5rem;
    border-style: solid;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    cursor: pointer;
}

.group-btn:nth-child(4n+1), .group:first-child {
    border-color: var(--umw-col-red);
}

.group-btn:nth-child(4n + 2) {
    border-color: var(--umw-col-orange);
}

.group-btn:nth-child(4n + 3) {
    border-color: var(--umw-col-yellow);
}

.group-btn:nth-child(4n + 4) {
    border-color: var(--umw-col-green);
}
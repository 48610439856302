#roulette-wheel {
    height: 100%;
    width: 100%;
    max-width: 75rem;
    position: relative;
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    grid-template-rows: repeat(7, minmax(0, 1fr));
    gap: 0 0;
    grid-auto-flow: row;
    grid-template-areas:
    "r1 r2 r3 r4 r5 r6 r7"
    "r24 puzzle puzzle puzzle puzzle puzzle r8"
    "r23 puzzle puzzle puzzle puzzle puzzle r9"
    "r22 puzzle puzzle puzzle puzzle puzzle r10"
    "r21 puzzle puzzle puzzle puzzle puzzle r11"
    "r20 puzzle puzzle puzzle puzzle puzzle r12"
    "r19 r18 r17 r16 r15 r14 r13";
    text-align: center;
}

.r1 { grid-area: r1; }

.r2 { grid-area: r2; }

.r3 { grid-area: r3; }

.r4 { grid-area: r4; }

.r5 { grid-area: r5; }

.r6 { grid-area: r6; }

.r7 { grid-area: r7; }

.r8 { grid-area: r8; }

.r9 { grid-area: r9; }

.r10 { grid-area: r10; }

.r11 { grid-area: r11; }

.r12 { grid-area: r12; }

.r13 { grid-area: r13; }

.r14 { grid-area: r14; }

.r15 { grid-area: r15; }

.r16 { grid-area: r16; }

.r17 { grid-area: r17; }

.r18 { grid-area: r18; }

.r19 { grid-area: r19; }

.r20 { grid-area: r20; }

.r21 { grid-area: r21; }

.r22 { grid-area: r22; }

.r23 { grid-area: r23; }

.r24 { grid-area: r24; }

#roulette-wheel .puzzle {
    grid-area: puzzle;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    position: relative;
}

#roulette-wheel > div {
    background-color: #282c34;
    border: 2px solid white;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7vw;
    padding: 1rem;
    cursor: pointer;
}

#roulette-wheel > div.dbg_item {
    background-color: chocolate !important;
}

#roulette-wheel > div.bankrupt {
    background-color: var(--umw-col-pink);
    font-weight: bold;
}

#roulette-wheel > div.free-vowel {
    background-color: var(--umw-col-purple);
    font-weight: bold;
}

#roulette-wheel > div.lose-a-turn {
    background-color: var(--umw-col-blue);
    font-weight: bold;
}

#roulette-wheel.g1 > div.active {
    background-color: var(--umw-col-red);
}

#roulette-wheel.g2 > div.active {
    background-color: var(--umw-col-orange);
}

#roulette-wheel.g3 > div.active {
    background-color: var(--umw-col-yellow);
}

#roulette-wheel.g4 > div.active {
    background-color: var(--umw-col-green);
}

#roulette-wheel .puzzle > #puzzle-board {
    height: calc(100% - 6rem);
    min-height: calc(100% - 6rem);
    width: 100%;
}

#spinBtn {
    animation: pulse 2s infinite;
    margin: auto;
}

#spinBtn.g1 {
    background-color: var(--umw-col-red);
}

#spinBtn.g2 {
    background-color: var(--umw-col-orange);
}

#spinBtn.g3 {
    background-color: var(--umw-col-yellow);
}

#spinBtn.g4 {
    background-color: var(--umw-col-green);
}

#spinBtn.locked {
    animation: none;
    visibility: hidden;
}

#roulette-wheel .puzzle-container {
    height: calc(85% - 5rem) !important;
}

#roulette-wheel #category-container {
    height: 15%;
    font-size: 2rem;
}


#group-display {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    flex: 25;
    background-color: var(--umw-col-panel);
}

#group-display.scrollbarOn .group {
    height: 108% !important;
    margin-bottom: -1rem;
}

.group {
    color: var(--umw-col-font-dark);
    padding: 0.6rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    cursor: pointer;
    background-color: var(--umw-col-panel);
    position: relative;
    border-width: 0.5rem;
    border-style: solid;
    border-radius: 1rem;
    width: 13.88rem;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    flex: 0 0 auto;
    user-select: none !important;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
}



.group:nth-child(4n+1), .group:first-child {
    border-color: var(--umw-col-red);
}

.group:nth-child(4n + 2) {
    border-color: var(--umw-col-orange);
}

.group:nth-child(4n + 3) {
    border-color: var(--umw-col-yellow);
}

.group:nth-child(4n + 4) {
    border-color: var(--umw-col-green);
}

.group.finished {
    border-color: var(--umw-col-grey) !important;
}

.group-options {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.6rem;
    color: var(--umw-col-font-dark);
    height: auto;
    width: auto;
    display: block;
    border-radius: 0;
    padding: 0 !important;
    min-width: 1.3rem;
}

.group .points-display {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 1rem;
}

.group input {
    border-radius: 0.5rem;
    max-height: 1.0rem;
    font-size: 1rem;
    padding: 6px;
    max-width: 8rem;
    background: var(--umw-col-default-btn);
    box-shadow: 0 0 1px 1px rgba(43, 43, 43, 0.5) inset;
    border: none;
}

.group .points-display > span {
    font-size: 1.4rem;
    display: flex;
}

.group-name {
    font-size: 1.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.group i {
    height: 1.8rem;
    width: 1.8rem;
    display: inline-block;
    margin-right: 1rem;
}

.group svg {
    max-width: 100%;
    max-height: 100%;
}

.group-options svg {
    height: 2.6rem;
    width: 1.6rem;
    right: 0.75rem;
}

#group-display:has(> :nth-child(4):last-child) {
    justify-content: space-evenly;
}

#group-items {
    min-height: 40px;
}

#group-items .item-box {
    background-color: var(--umw-col-default-btn);
    box-shadow: 0 0 1px 1px rgba(43, 43, 43, 0.5) inset;
    height: 100%;
    border-radius: 0.5rem;
}

.item-box i {
    margin: auto;
}


#item-effect-points-popup {
    box-sizing: border-box;
    position: absolute;
    width: 69.3%;
    left: 0;
    right: 0;
    margin: auto !important;
    top: 0;
    bottom: 0;
    background-color: var(--umw-col-panel);
    height: 68%;
    padding: 1rem;
    z-index: 999;
}

#item-effect-points-popup  .item-title {
    font-size: 2.3rem;
    text-align: center;
    display: block;
}

#item-effect-points-popup  .item-icon {
    height: 7rem !important;
    display: block;
}

#item-effect-points-popup  .item-description {
    display: block;
    text-align: center;
    font-size: 1.3rem;
    margin-top: 0;
}

#item-effect-points-popup #group-hud-holder {
    border-left: 1px solid rgba(0,0,0,0.5);
    padding-left: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

#item-effect-points-popup #currentGroupHUD {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12rem;

}

#item-effect-points-popup #currentGroupHUD .group {
    cursor: default !important;
}
.group.g1 {
    border-color: var(--umw-col-red) !important;
}

.group.g2 {
    border-color: var(--umw-col-orange) !important;
}

.group.g3 {
    border-color: var(--umw-col-yellow) !important;
}

.group.g4 {
    border-color: var(--umw-col-green) !important;
}
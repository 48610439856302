#puzzle-board {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

#puzzle-board .puzzle-container {
    display: grid;
    padding: 1rem;
    grid-template-columns: repeat(15, minmax(0, 1fr));
    grid-template-rows: repeat(4, minmax(0, 1fr));
    gap: 0.5em 0.5em;
    grid-auto-flow: row;
    grid-template-areas:
    "l1 l2 l3 l4 l5 l6 l7 l8 l9 l10 l11 l12 l13 l14 l15"
    "l16 l17 l18 l19 l20 l21 l22 l23 l24 l25 l26 l27 l28 l29 l30"
    "l31 l32 l33 l34 l35 l36 l37 l38 l39 l40 l41 l42 l43 l44 l45"
    "l46 l47 l48 l49 l50 l51 l52 l53 l54 l55 l56 l57 l58 l59 l60";
    height: calc(97% - 9rem);
    width: calc(100% - 4rem);
    background-color: #2b2b2bd1;
}

.puzzle-container > div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: bold;
    color: transparent;
    user-select: none;
    cursor: pointer;
    padding: 0 !important;
}

.puzzle-container > div.show {
    color: var(--umw-col-font-dark);
}

.puzzle-container > div.used {
    background-color: var(--umw-col-panel);
    font-size: 2rem;
    min-width: 2.2rem;
}

.puzzle-container > div.guessed {
    background-color: var(--umw-col-green);
}

.l1 { grid-area: l1; }

.l2 { grid-area: l2; }

.l3 { grid-area: l3; }

.l4 { grid-area: l4; }

.l5 { grid-area: l5; }

.l6 { grid-area: l6; }

.l7 { grid-area: l7; }

.l8 { grid-area: l8; }

.l9 { grid-area: l9; }

.l10 { grid-area: l10; }

.l11 { grid-area: l11; }

.l12 { grid-area: l12; }

.l13 { grid-area: l13; }

.l14 { grid-area: l14; }

.l15 { grid-area: l15; }

.l16 { grid-area: l16; }

.l17 { grid-area: l17; }

.l18 { grid-area: l18; }

.l19 { grid-area: l19; }

.l20 { grid-area: l20; }

.l21 { grid-area: l21; }

.l22 { grid-area: l22; }

.l23 { grid-area: l23; }

.l24 { grid-area: l24; }

.l25 { grid-area: l25; }

.l26 { grid-area: l26; }

.l27 { grid-area: l27; }

.l28 { grid-area: l28; }

.l29 { grid-area: l29; }

.l30 { grid-area: l30; }

.l31 { grid-area: l31; }

.l32 { grid-area: l32; }

.l33 { grid-area: l33; }

.l34 { grid-area: l34; }

.l35 { grid-area: l35; }

.l36 { grid-area: l36; }

.l37 { grid-area: l37; }

.l38 { grid-area: l38; }

.l39 { grid-area: l39; }

.l40 { grid-area: l40; }

.l41 { grid-area: l41; }

.l42 { grid-area: l42; }

.l43 { grid-area: l43; }

.l44 { grid-area: l44; }

.l45 { grid-area: l45; }

.l46 { grid-area: l46; }

.l47 { grid-area: l47; }

.l48 { grid-area: l48; }

.l49 { grid-area: l49; }

.l50 { grid-area: l50; }

.l51 { grid-area: l51; }

.l52 { grid-area: l52; }

.l53 { grid-area: l53; }

.l54 { grid-area: l54; }

.l55 { grid-area: l55; }

.l56 { grid-area: l56; }

.l57 { grid-area: l57; }

.l58 { grid-area: l58; }

.l59 { grid-area: l59; }

.l60 { grid-area: l60; }

#next-puzzle-btn {
    background-color: var(--umw-col-panel-dark);
    animation: pulse 2s infinite;
}

#category-container, #next-container {
    height: calc(24% - 2rem);
    width: calc(100% - 2rem);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    color: var(--umw-col-font-light);
    background-color: #2b2b2bd1;
}

#next-container {
    background-color: transparent;
}
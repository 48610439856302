#letter-selector {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0 0;
    grid-auto-flow: row;
    grid-template-areas:
    "a1 a2 a3 a4 a5 a6 a7"
    "a26 puzzle puzzle puzzle puzzle puzzle a8"
    "a25 puzzle puzzle puzzle puzzle puzzle a9"
    "a24 puzzle puzzle puzzle puzzle puzzle a10"
    "a23 puzzle puzzle puzzle puzzle puzzle a11"
    "a22 puzzle puzzle puzzle puzzle puzzle a12"
    "a21 puzzle puzzle puzzle puzzle puzzle a13"
    "a20 a19 a18 a17 a16 a15 a14";
    text-align: center;
}

.a1 { grid-area: a1; }

.a2 { grid-area: a2; }

.a3 { grid-area: a3; }

.a4 { grid-area: a4; }

.a5 { grid-area: a5; }

.a6 { grid-area: a6; }

.a7 { grid-area: a7; }

.a8 { grid-area: a8; }

.a9 { grid-area: a9; }

.a10 { grid-area: a10; }

.a11 { grid-area: a11; }

.a12 { grid-area: a12; }

.a13 { grid-area: a13; }

.a14 { grid-area: a14; }

.a15 { grid-area: a15; }

.a16 { grid-area: a16; }

.a17 { grid-area: a17; }

.a18 { grid-area: a18; }

.a19 { grid-area: a19; }

.a20 { grid-area: a20; }

.a21 { grid-area: a21; }

.a22 { grid-area: a22; }

.a23 { grid-area: a23; }

.a24 { grid-area: a24; }

.a25 { grid-area: a25; }

.a26 { grid-area: a26; }

#letter-selector .puzzle {
    grid-area: puzzle;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
}

#letter-selector .puzzle > #puzzle-board {
    height: calc(100% - 6rem);
    min-height: calc(100% - 6rem);
    width: 100%;
}

#letter-selector .puzzle-container {
    height: calc(85% - 5rem) !important;
}

#letter-selector #category-container {
    height: 15%;
    font-size: 2rem;
}

#letter-selector .puzzle > #timer-overlay {
    width: 100%;
    height: 4rem;
    margin: auto;
}

#letter-selector > div {
    background-color: #282c34;
    border: 2px solid white;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
    padding: 1rem;
    cursor: pointer;
    user-select: none;
}

#letter-selector > div.vowel {
    background-color: var(--umw-col-blue);
}

#letter-selector > div.disabled {
    position: relative;
}

#letter-selector > div.disabled:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 3rem;
    font-weight: bold;
    background-color: #282c34;
    color: var(--umw-col-font-light);
}

#letter-selector > div.vowel.disabled:after {
    content: '×';
    background-color: var(--umw-col-blue);
}

#letter-selector.is-free > div.vowel {
    background-color: var(--umw-col-purple);
}

#letter-selector > div.used.ng {
    background-color: var(--umw-col-red);
}

#letter-selector > div.used.correct {
    background-color: var(--umw-col-green);
}

#letter-selector > div.used, #letter-selector > div.disabled {
    pointer-events: none;
    cursor: not-allowed;
}
#game-setup {
    background-color: var(--umw-col-panel-dark);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 2rem;
    height: calc(100% - 4rem);
}

#game-setup .panel {
    background-color: var(--umw-col-panel);
    padding: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
    height: calc(100% - 2rem);
}

.panel button {
    background-color: var(--umw-col-panel-dark);
    color: var(--umw-col-font-light);
}

.panel h2 {
    margin: 0;
}
.panel header {
    height: 80px;
    margin-top: -1rem;
    margin-left: -1rem;
    margin-right: -1rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding: 1rem 1rem 0 1rem;
    font-size: 3rem;
    line-height: 3.5rem;
    border-bottom: 1px solid var(--umw-col-panel-dark)
}

.panel .nav-holder {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 1rem;
}

.dec-inc-input {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
    justify-content: center;
}

.dec-inc-input .dec-btn, .dec-inc-input .inc-btn {
    color: var(--umw-col-font-light);
    background-color: var(--umw-col-panel-dark);
    height: 48px;
    width: 45px;
    margin: 0;
}

.dec-inc-input .dec-btn {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.dec-inc-input .inc-btn {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.dec-inc-input .value {
    flex: 1 0;
    padding: 0;
    margin: 0;
    border-top: 4px solid var(--umw-col-panel-dark);
    border-bottom: 4px solid var(--umw-col-panel-dark);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.5rem;
    border-left: none;
    border-right: none;
    min-width: 7.96rem !important;
    height: 40px;
    border-radius: 0;
    width: 7.96rem !important;
}

#groupsPanel {
    display: flex;
    gap: 1rem;
}

.settings-container-1, .settings-container-2 {
    display: flex;
    gap: 1rem;
    flex: 1;
    flex-direction: row;
}

.settings-container-1 {
    flex-direction: row;
    text-align: center;
    gap: 1rem;
    padding: 1rem;
}

.panel h1 {
    margin: 0;
}

.settings-container-1 h1 {
    margin: 0px 1rem 1rem 1rem;
}

.settings-container-1 > div {
    flex: 1 0 50%;
}

.settings-container-2 {
    flex-direction: column;
    width: 100%;
}

.scrolling-wrapper {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 1rem;
    flex: 1 1;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    align-items: center;
    overflow-y: hidden;
    padding-bottom: 0.8125rem;
}

.scrolling-wrapper .card {
    flex: 0 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 100px;
    height: 100px;
    background-position: center;
    cursor: pointer;
}

.scrolling-wrapper .card.active {
    border: 5px solid var(--umw-col-panel-dark);
}

.scrolling-wrapper::-webkit-scrollbar-thumb {
    background-color: var(--umw-col-panel-dark);
}

#add-puzzle-btn {
    background-color: var(--umw-col-green);
    width: 45px;
    height: 45px;
    position: absolute;
    right: 1rem;
}

.puzzle-row input {
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid var(--umw-col-grey);
}

.puzzle-row input.invalid {
    background-color: #C628284F;
}

.delete-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    background: none !important;
    border: none;
    color: var(--umw-col-red) !important;
}

#puzzle-set-holder {
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1 1;
    margin: 1rem;
    min-height: calc(51.5% - 2rem);
    max-height: 19.8rem;
}

#puzzle-set-holder.items {
    min-height: calc(100% - 5rem);
    margin: 0 1rem;
}

.custom-file-upload {
    height: 4rem;
    width: 16rem;
    border: 1px solid var(--umw-col-panel-dark);
    background-color: var(--umw-col-panel-dark);
    border-radius: 999rem;
    color: var(--umw-col-font-light) !important;
    margin: 0 0 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    cursor: pointer;
}

.panel .item-row i.item-icon {
    height: 3.5rem;
    width: 3.5rem;
}

.panel .item-row {
    margin-right: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.item-row:last-of-type + hr {
    display: none;
}

/* Sliders */

/* The switch - the box around the slider */
.switch {
    display: inline-block;
    height: 2.125rem;
    position: relative;
    width: 3.75rem;
}

/* Hide default HTML checkbox */
.switch input {
    height: 0;
    opacity: 0;
    width: 0;
}

/* The slider */
.slider {
    background-color: var(--umw-col-panel);
    border: 1px solid var(--umw-col-panel-dark);
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    background-color: var(--umw-col-panel-dark);
    bottom: 0.25rem;
    content: "";
    height: 1.625rem;
    left: 0.25rem;
    position: absolute;
    -webkit-transition: .4s;
    transition: .4s;
    width: 1.625rem;
}

input:checked + .slider {
    background-color: var(--umw-col-panel-dark);
}

input:checked + .slider:before {
    background-color: var(--umw-col-panel);
}

input:focus + .slider {
    box-shadow: 0 0 0.0625rem var(--umw-col-panel-dark);
}

input:checked + .slider:before {
    -ms-transform: translateX(1.625rem);
    -webkit-transform: translateX(1.625rem);
    transform: translateX(1.625rem);
}

/* Rounded sliders */
.slider.round {
    border-radius: 2.125rem;
}

.slider.round:before {
    border-radius: 50%;
}

input:disabled + .slider {
    background-color: #4a4a4a;
    cursor: default;
}


.label {
    align-items: center;
    background-color: var(--umw-col-panel-dark);
    border-radius: 0.25rem;
    color: #fff;
    display: inline-flex;
    font-weight: 700;
    justify-content: center;
    line-height: 1;
    margin: 0.3rem !important;
    padding: 0.5rem;
}




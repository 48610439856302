#solve-the-puzzle {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#solve-the-puzzle > #puzzle-board {
    width: 100%;
    height: 64%;
}

#response-holder {
    display: flex;
    gap: 1rem;
    height: 36% !important;
    min-height: 36%;
    align-items: center;
    justify-content: center;
    position: relative;
}

#correctSolveBtn {
    background-color: var(--umw-col-red);
}

#incorrectSolveBtn {
    background-color: var(--umw-col-blue);
}

#continueBtn {
    background-color: var(--umw-col-green);
    animation: pulse 2s infinite;
}


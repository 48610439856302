#item-popup {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    margin: auto;
    height: calc(66% - 4.3rem);
    background-color: var(--umw-col-panel);
    padding: 1rem;
}

#item-popup .item-title {
    font-size: 2.3rem;
    text-align: center;
    display: block;
}

#item-popup .item-icon {
    height: 10rem !important;
    display: block;
}

#item-popup .item-description {
    display: block;
    text-align: center;
    font-size: 1.3rem;
    margin-top: 0;
}

.puzzle > #item-popup {
    color: var(--umw-col-font-dark);
    bottom: 1rem;
    height: auto;
}
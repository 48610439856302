#podium {
    height: 310px;
    gap: 0.5rem;
    align-items: end;
    place-content: center;
    grid-auto-flow: column dense;
    display: grid;
    box-sizing: border-box;
    margin: 1rem auto 0 auto;
    justify-items: center;
    justify-content: center;
    align-content: stretch;
}

.place-img {
    height: 6rem;
    width: 6rem;
    margin-bottom: 0.25rem;
    align-self: center;
}

.place-img img {
    width: 100%;
    height: 100%;
}

#podium > div {
    align-self: end !important;
}

.podium-step {
    align-self: end !important;
    place-content: center;
    flex-direction: column;
    display: flex;
}

.podium-bar {
    width: 16rem;
    display: flex;
    place-content: center;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-width: 1px;
    border-bottom-width: 0;
    cursor: pointer;
}

.step-0 .podium-bar {
    background-color: var(--umw-col-red);
}

.step-1 .podium-bar {
    background-color: var(--umw-col-green);
}

.step-2 .podium-bar {
    background-color: var(--umw-col-blue);
}

.podium-bar > span {
    font-size: 2rem;
    color: var(--umw-col-font-light);
    align-self: flex-end;
    font-weight: 600;
    margin-bottom: 0.5rem;
    white-space: break-spaces;
}

.winners-list-holder {
    margin: auto;
    width: 66%;
}

.winner-card {
    padding: 1rem;
    border-width: 1px;
    border-radius: 0.5rem;
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-top: 1rem;
    background: var(--umw-col-panel);
    gap: 1rem;
    max-height: 3rem;
}

.winner-card-place {
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 600;
    width: 3.5rem;
}

.winner-card-img {
    width: 2.75rem;
    height: 2.75rem;
    margin-right: 0.75rem;
    max-width: 100%;
    display: block;
    vertical-align: middle;
}

.winner-card-info {
    font-size: .875rem;
    line-height: 1.25rem;
    flex-grow: 1;
    display: flex;
    padding: 1rem 1rem 1rem 0;
    align-items: center;
    justify-content: space-between;
}

.winner-card-info > p:nth-child(1) {
    line-height: 1;
    font-weight: 600;
    margin: 0;
    font-size: 2rem;
    white-space: break-spaces;
}

.winner-card-info > p:nth-child(2) {
    margin: 0;
    font-size: 2rem;
}

.winner-card-tbb {
    height: 3rem;
    width: 3rem;
    margin-right: 0.75rem;
    max-width: 100%;
    display: block;
    vertical-align: middle;
}

#podium-continue-btn {
    margin: 0.67rem auto 1rem auto;
    min-height: 4rem !important;
    animation: pulse 2s infinite;
}

#no-winners > div {
    font-size:3rem;
    font-weight: bold;
    color: var(--umw-col-font-light);
}

.tied-text {
    font-size: 1rem;
    display: block;
}



.modal ul li {
    margin-bottom: 1rem;
}

.modal .icon {
    height: 1.5rem;
    width: 1.5rem;
    display: inline-block;
    margin-right: 0.5rem;
}

.modal.fade.show {
    display: block !important;
}

.modal-backdrop {
    content: '';
    z-index: 1000;
    background-color: #000;
    opacity: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    top:0;
    left:0;
    transition: opacity .15s linear;
}

.modal.fade.show + .modal-backdrop {
    opacity: 0.5;
}

.modal.fade:not(.show) {
    opacity: 0;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    height: 97%;
    width: 60%;
    margin: auto;
    right: 0;
    bottom: 0;
    padding-right: 3rem;
    z-index: 1500;
}

.fade {
    transition: opacity .15s linear;
}

.modal.fade .modal-dialog {
    margin-right: auto;
    margin-left: auto;
    position: relative;
    width: auto;
    pointer-events: none;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--umw-col-font-dark);
    pointer-events: auto;
    background-color: var(--umw-col-panel);
    background-clip: padding-box;
    border-radius: 1rem;
    outline: 0;
    padding: 1rem;
}

.modal-header {
    display: flex;
    gap: 0.5rem!important;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
}

.modal-body {
    overflow: auto;
    margin-bottom: 1rem;
    position: relative;
    flex: 1 1 auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 2rem;
    font-size: 2rem;
}



.modal-header .btn-close {
    min-width: 3rem !important;
    width: 3rem !important;
    cursor: pointer;
}

.btn-close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    background: none !important;
    border: none;
    color: var(--umw-col-red) !important;
    font-size: 3rem;
}

.no-bullets {
    list-style: none;
}

.buttons.no-bullets > li {
    margin-bottom: 1rem;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(204, 204, 204, 0.6);
    border-radius: 9999px;
    background-clip: padding-box;
    border: 4px solid rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
    background-color: rgba(204, 204, 204, 0.6);
    border-radius: 20.3125rem;
}

@media screen and (min-width: 760px) {
    ::-webkit-scrollbar {
        width: 1rem;
    }
}



#turn-option-selector {
    display: flex;
    gap: 1rem;
    height: 36% !important;
    min-height: 36%;
    align-items: center;
    position: relative;
}

#turn-option-selector button {
    font-size: 2rem;
}

#turn-option-selector.g1 button {
    background-color: var(--umw-col-red) !important;
}

#turn-option-selector.g2 button {
    background-color: var(--umw-col-orange) !important;
}

#turn-option-selector.g3 button {
    background-color: var(--umw-col-yellow) !important;
}

#turn-option-selector.g4 button {
    background-color: var(--umw-col-green) !important;
}

.group.g1 {
    border-color: var(--umw-col-red) !important;
}

.group.g2 {
    border-color: var(--umw-col-orange) !important;
}

.group.g3 {
    border-color: var(--umw-col-yellow) !important;
}

.group.g4 {
    border-color: var(--umw-col-green) !important;
}

#turn-option-selector #cancelBtn {
    color: var(--umw-col-font-dark);
    font-size: 4.5rem;
    font-weight: bold;
    width: 40px !important;
    height: 40px !important;
    border: none;
    display: flex;
    line-height: 1rem;
    background-color: var(--umw-col-panel) !important;
}

#turn-option-selector button.disabled {
    pointer-events: none;
    background-color: var(--umw-col-grey) !important;
}

#turn-option-selector #group-hud-container {
    flex: 25 0;
    height: calc(100% - 2rem);
    padding: 1rem;
    background-color: var(--umw-col-panel);
}

#turn-option-selector #button-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: auto;
}

#button-holder > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#currentGroupHUD {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

}

#currentGroupHUD .group {
    cursor: default !important;
    height: 100%;
}

#currentGroupHUD .group .points-display {
    flex: 1;
    justify-content: flex-start;
}


#turn-option-selector  .timer-wrapper {
   flex: 1;
}